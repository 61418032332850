import React from 'react';
import {get, debounce} from 'lodash';
import {Wrapper} from './Slider.styles'


class SlickSlider extends React.Component {

  constructor(...args) {
    super(...args);
    this.resizeHandler = debounce(this.handleResize.bind(this), 300);
    this.state = {
      index: this.props.initialSlideIndex || 0
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
    this.slideToShowChange(this.props);
    this.setState({
      render: true
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  handleResize() {
    this.slideToShowChange(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const s = nextProps.slidesToShow;
    if (s !== this.props.slidesToShow && s >= 0) {
      this.slideToShowChange(nextProps);
    }
  }


  slideToShowChange(props) {
    const s = props.slidesToShow;
    const index = this.state.index;
    const elWidth = 100/s;
    const element = this.transformContainer;
    if (element) {
      const width = element.offsetWidth;
      const left = Math.round(((elWidth/100) * width) * index);
      // console.log((elWidth/100), width, index)
      element.style.WebkitTransform = `translate3d(${-left}px, 0px, 0px)`;
      element.style.transform = `translate3d(${-left}px, 0px, 0px)`;
      element.style.msTransform = `translate3d(${-left}px, 0px, 0px)`;
      element.style.WebkitTransition = '-webkit-transform 0ms ease';
      element.style.transition = 'transform 0s ease';
    }
  }

  render() {
    const end = (this.state.index + this.props.slidesToShow) >= get(this.props, 'children.length');
    const beginning = this.state.index <= 0;
    const childrenWithWrapperDiv = React.Children.map(this.props.children, (child, i) => {
      const unitWidth = get(child, 'props.children.props.unitWidth', 1);
      return (
        <div 
          onClick={null} 
          tabIndex={1000} 
          key={i} 
          ref={(r) => this[`card-${i}`] = r} 
          className="p-slick-slide slide" 
          style={{maxWidth: `${(100/this.props.slidesToShow) * unitWidth}%`, minWidth: `${(100/this.props.slidesToShow) * unitWidth}%`}}>
            {child}
        </div>
      );
    });
    return (
      <Wrapper>
        <div className={`p-slick-slider-wrapper ${this.props.loading ? 'loading' : ''}`}>
          <div onClick={this.onClickPrev.bind(this)} className={`p-slick-arrow p-slick-prev ${beginning ? 'p-slick-disabled' : ''}`}></div>
          <div className="p-slick-initialized p-slick-slider center">
            <div style={this.state.style} ref={(r) => this.transformContainer = r} className="p-slick-track">
              {childrenWithWrapperDiv}
            </div>
          </div>
          <div onClick={this.onClickNext.bind(this)} className={`p-slick-arrow p-slick-next ${end ? 'p-slick-disabled' : ''}`}></div>
        </div>
      </Wrapper>
    );
  }

  onClickNext() {
    if (this.animating) return false;
    this.animating = true;
    const slidesToScroll = this.props.slidesToScroll || 3;
    const index = this.state.index;
    let newIndex = index + slidesToScroll;
    const elWidth = 100/this.props.slidesToShow;
    const element = this.transformContainer;
    const width = element.offsetWidth;
    const slidesToShow = this.props.slidesToShow;
    const slideCount = get(this.props, 'children.length');
    const end = (newIndex + slidesToShow) >= slideCount;
    if (end) {
      newIndex = newIndex - (newIndex + slidesToShow - slideCount);
    }

    const left = Math.round(((elWidth/100) * width) * newIndex);
    element.style.WebkitTransition = '-webkit-transform 0.3ms ease';
    element.style.transition = 'transform 0.3s ease';
    element.style.WebkitTransform = `translate3d(${-left}px, 0px, 0px)`;
    element.style.transform = `translate3d(${-left}px, 0px, 0px)`;
    element.style.msTransform = `translate3d(${-left}px, 0px, 0px)`;

    setTimeout(() => {
      this.setState({index: newIndex});
      if (this.props.afterChange) {
        this.props.afterChange(newIndex);
      }
      this.animating = false;
    }, 400);
  }

  onClickPrev() {
    if (this.animating) return false;
    this.animating = true;
    const slidesToScroll = this.props.slidesToScroll || 3;
    const index = this.state.index;

    let newIndex = index - slidesToScroll;
    const elWidth = 100/this.props.slidesToShow;
    const element = this.transformContainer;
    const width = element.offsetWidth;
    let left = Math.round(((elWidth/100) * width) * newIndex);
    if (left < 0) {
      left = 0;
    }
    element.style.WebkitTransition = '-webkit-transform 0.3ms ease';
    element.style.transition = 'transform 0.3s ease';
    element.style.WebkitTransform = `translate3d(${-left}px, 0px, 0px)`;
    element.style.transform = `translate3d(${-left}px, 0px, 0px)`;
    element.style.msTransform = `translate3d(${-left}px, 0px, 0px)`;

    

    setTimeout(() => {
      this.setState({index: newIndex});
      if (this.props.afterChange) {
        this.props.afterChange(newIndex);
      }
      this.animating = false;
    }, 400);
  }
}

export default SlickSlider;
