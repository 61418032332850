import React, {useEffect, useState} from 'react';
import {trackClick} from 'utility/analytics';
import {openModal} from 'state/modals/actions';
import {Link} from "react-router-dom";
import RelatedProductsSlider from 'components/RelatedProductsSlider/RelatedProductsSlider'
import { Row, Col } from '@zendeskgarden/react-grid';
import { LG, MD, XL } from '@zendeskgarden/react-typography';
import { filter, map, get, sortBy } from 'lodash'
import { StyledLandingFeaturedListings } from './LandingFeaturedListings.styles.js';



const LandingFeaturedListings = (props) => {
  const listingsLength = props.listings && props.listings.length;
  const [listingsState, setListingsState] = useState(null);
  useEffect(()=> {
    if (listingsLength) {
      let sortedListings = sortBy(props.listings, 'listing_model');
      const mri = filter(sortedListings, ({listing_model, listing_type}) => listing_type === 'MRI' &&  (listing_model === '1.5T' || listing_model === '3.0T'))
      const ct = filter(sortedListings, ({listing_model, listing_type}) => listing_type === 'CT')
      setListingsState([mri, ct]);
    }
  }, [listingsLength]);


  function goTorequest(e) {
    e.preventDefault()
    trackClick('inquireForm', 'Submit A Request', 'LandingFeaturedListings');
    openModal({
      type: 'inquireFormModal',
    })  
  }
  

  return (
    <StyledLandingFeaturedListings>
        <Row>
          <Col>
            {
              get(listingsState, '[0]') ?
              <div>
                <div style={{padding: '0 10px', marginBottom: '20px'}}>
                  <Row justifyBetween>
                    <Col>
                      <MD bold uppercase blue>EQUIPMENT INVESTMENT</MD>
                      <XL navy scaled bold paddingTopLg>A Quality-First Approach To Buying Imaging Equipment</XL>
                      <LG style={{maxWidth: '1090px'}} paddingTopMd navy light tag="h3">
                        Specializing in refurbished and after market Imaging Systems, MRT offers you only the highest quality inventory. This is only a sample of our 100s of products, if you dont find what you are looking for <Link className="bold" to="/c/medical-equipment">View Our Equipment</Link> or <Link onClick={goTorequest} className="bold" to="">Submit A Request</Link>.
                      </LG>

                      <MD navy paddingTopXl uppercase bold>Featured 1.5T and 3T MRI Systems</MD>
                    </Col>
                  </Row>
                </div>
                <RelatedProductsSlider maxSlides={5} data={get(listingsState, '[0]')} />
              </div> : null
            }


            {
              get(listingsState, '[1]') ?
              <div>
                <div style={{padding: '40px 10px 0', marginBottom: '20px'}}>
                  <Row justifyBetween>
                    <Col>
                      <MD navy paddingTopXl uppercase bold>Featured CT Systems</MD>
                    </Col>
                  </Row>
                </div>
                <RelatedProductsSlider maxSlides={5} data={get(listingsState, '[1]')} />
              </div> : null
            }
          </Col>
        </Row>
    </StyledLandingFeaturedListings>

  )
}


export default LandingFeaturedListings;
