import React from 'react';
import { Link } from 'react-router-dom';
import {SM, LG} from "@zendeskgarden/react-typography";
import moment from 'moment';


import {ProductImageWrapper, Wrapper, ItemContainer, ProductImage, ProductContent, ItemContainerWrapper} from './ContentItemSlider.styles'


const ContentSliderItem = (props) => {

  return (
    <Wrapper style={props.style || {}} className={"wrap"}>
      <ItemContainerWrapper>
        <ItemContainer style={{paddingBottom: '0px'}}>
          <ProductImageWrapper>
            <ProductImage noImage={!props.image} style={{backgroundImage: `url("${props.image}")`}} alt=""/>
          </ProductImageWrapper>
          <ProductContent>
            <SM slate semibold style={{lineHeight: '24px'}}>{moment(props.updated_at).format('MMMM D')}</SM>
            <LG paddingTopSm navy black style={{lineHeight: '24px'}}>{props.content_title}</LG>
            <SM paddingTopLg navy style={{lineHeight: '24px'}} blue link>Read more</SM>
          </ProductContent>
          <Link to={{
            pathname: props.url || `/${props.content_type||'blog'}/${props.content_slug||'content'}/${props.id}`,
            state: {
              fromInternal: props.fromInternal
            }
          }}
          style={{position: 'absolute', top: 0, right: 0, bottom: -55, left: 0}}>


          </Link>

        </ItemContainer>
      </ItemContainerWrapper>
    </Wrapper>
  )
}


export default ContentSliderItem