import React from 'react';
import history from 'historyContainer';
import { Link } from 'react-router-dom';
import { SM } from '@zendeskgarden/react-typography';
import { StyledLandingSearch, StyledLandingSearchWrapper, Header, Actions, Action } from './LandingSearch.styles.js';

const LandingSearch = ({style, ignoreButton, searchOnChange, hideWhenGlobalSearchOpen}) => {

  return (
    <StyledLandingSearchWrapper style={style || {}}>
      <StyledLandingSearch>
        <Header>
          <SM  bold navy uppercase tag="p">INVENTORY SEARCH</SM>
          {/*<SM  bold navy uppercase tag="p">NOT SURE? <Link to="/blog">VIEW OUR RESOURCES</Link></SM>*/}
        </Header>
       
       <Actions>
        <Action onClick={()=> history.push('/c/equipment/mri/1.5t-mri')}>
           <img alt="1.5T MRI" src="https://res.cloudinary.com/hbq7zoknx/image/upload/v1604633025/one-t_ytim3a.svg" />
           <SM paddingTopMd navy bold>1.5T MRI</SM>
         </Action>
         <Action onClick={()=> history.push('/c/equipment/mri/3t-mri')}>
           <img alt="3T MRI" src="https://res.cloudinary.com/hbq7zoknx/image/upload/v1604633025/3t_vqdy4p.svg" />
           <SM paddingTopMd navy bold>3T MRI</SM>
         </Action>
         <Action onClick={()=> history.push('/c/equipment/ct')}>
           <img alt="CT Scanner" src="https://res.cloudinary.com/hbq7zoknx/image/upload/v1604633025/ct_mdpm0k.svg" />
           <SM paddingTopMd navy bold>CT Scanner</SM>
         </Action>
         <Action onClick={()=> history.push('/c/equipment/pet-ct')}>
           <img alt="PET / CT" src="https://res.cloudinary.com/hbq7zoknx/image/upload/v1604633025/mri-in_kj9qoi.svg" />
           <SM paddingTopMd navy bold>PET / CT</SM>
         </Action>
       </Actions>
      </StyledLandingSearch>
    </StyledLandingSearchWrapper>

  )
}


export default LandingSearch;
