import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  .p-slick-slider-wrapper {
    width: 100%; 
    position: relative;
    .p-slick-slider {
      width: 100%;
      overflow: hidden;
      position: relative;
      backface-visibility: hidden;
    }
    .p-slick-track {
      width: auto;
      min-width: 100%;
      white-space: nowrap;
      display: flex;
      margin-left: 4px;
      backface-visibility: hidden;
      .slide {
        outline: none;
        display: flex;
        width: 100%;
      }
    }
    .p-slick-prev, .p-slick-next {
      width: 30px;
      max-width: 30px;
      min-width: 30px;
      top: 5px;
      bottom: 5px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;
      transition: all 0.3s ease;
      transform: scale(1);
      transform-origin: 0;
      margin: 0;
      height: auto;
      z-index: 2;
      cursor: pointer;
      &:after {
        content: "";
        width: 40px;
        heigfht: 40px;
        height: 40px;
        position: absolute;
        background: white;
        top: 50%;
        margin-top: -16px;
        z-index: 1;
        border-radius: 100%;
        box-shadow: -1px 1px 10px rgba(0, 0, 0, 0.17);
        background: #fff;
        transition: all 0.3s ease;
      }
      &:before {
        content: "" !important;
        font-size: 20px;
        border-style: solid;
        border-width: 0.10em 0.10em 0 0;
        position: absolute;
        display: inline-block;
        height: 0.45em;
        top: 50%;
        margin-top: -2px;
        z-index: 2;
        transform: rotate(-45deg);
        vertical-align: top;
        width: 0.45em;
        z-index: 3;
        color: @silver !important;
      }
      &:hover {
        &:after {
          box-shadow: -1px 1px 13px rgba(0, 0, 0, 0.2);
        }
      }
      &:active {
        transform: scale(1.055);
        &:after {
          box-shadow: -1px 1px 18px rgba(0, 0, 0, 0.19);
        }
      }
      
      &.p-slick-disabled {
        opacity: 0;
        cursor: default;
        pointer-events: none;
        &:after {
          opacity: 0;
        }
      }
    }
    .p-slick-prev {
      margin-left: -5px;
      left: -25px;
      top: 0;
      bottom: 0;
      position: absolute;
      &:before {
        left: 23px;
        transform: rotate(-135deg);
      }
      &:after {
        left: 7px;
      }
    }
    .p-slick-next {
      margin-right: -3px;
      right: -25px;
      top: 0;
      bottom: 0;
      position: absolute;
      &:after {
        left: -28px;
      }
      &:before {
        left: -13px;
        transform: rotate(45deg);
      }
    }
    &.loading {
      .p-slick-prev, .p-slick-next {
        display: none;
      }
    }
  }
`