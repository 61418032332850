import {variables} from 'theme/variables';
import {typographyBaseStyles} from 'theme/ThemeProviderOverrides/Typography';

const {
  custom_black,
  custom_navy,
  public_font_family,
  // public_font_size_xs,
  public_font_size_sm,
  public_font_size_md,
  // public_font_size,
  public_font_size_lg,
  public_font_size_xl,
  public_font_size_xxl,
  public_font_size_xxxl,
  // public_mobile_font_size_xs,
  public_mobile_font_size_sm,
  public_mobile_font_size_md,
  public_mobile_font_size_lg,
  public_mobile_font_size_xl,
  public_mobile_font_size_xxl,
  public_mobile_font_size_xxxl,

  public_font_weight_bold,
  media_md,

  public_line_height_xxxl,
  public_line_height_xxl,
  public_line_height_xl,
  public_line_height_lg,
  public_line_height_md,
  public_line_height_sm,
} = variables;

const TypographyTheme = {
  'typography.xxxl': props => {
    let styles = publicBaseStyles(props, 'XXXL')
     return `
      ${styles}
    `;
  },
  'typography.xxl': props => {
    let styles = publicBaseStyles(props, 'XXL')
     return `
      ${styles}
    `;
  },
  'typography.xl': props => {
    let styles = publicBaseStyles(props, 'XL')
     return `
      ${styles}
    `;
  },
  'typography.lg': props => {
    let styles = publicBaseStyles(props, 'LG')
     return `
     
      ${styles}
    `;
  },
  'typography.md': props => {
    let styles = publicBaseStyles(props, 'MD')
     return `
      ${styles}
    `;
  },
  'typography.sm': props => {
    let styles = publicBaseStyles(props, 'SM')
    return `
      ${styles}
    `;
  }
};

function publicBaseStyles(props, size) {
  return `
    font-display: swap;
    font-weight: normal;
    color: ${custom_navy};
    ${typographyBaseStyles(props, size)}
    font-family: ${public_font_family};

    ${size === 'MD' || size === 'SM' ? `
      letter-spacing: 0.5px;
      ${props.uppercase ? `
        letter-spacing: 1px;
      ` : ''}
    ` : ''}
  
    ${props.light ? `
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-smoothing: antialiased;
      letter-spacing: 0.5px;
    ` : ''}

    ${size === 'XXXL' ? `
      font-size: ${public_mobile_font_size_xxxl};
      line-height: 38px;
      @media ${media_md} {
        font-size: ${public_font_size_xxxl};
        line-height: ${public_line_height_xxxl};
      }
    ` : ''}

    ${size === 'XXL' ? `
      font-size: ${public_mobile_font_size_xxl};
      line-height: 38px;
      
      ${props.as === 'h1' ? `
        font-size: 32px;
        line-height: 42px;` : ''
      }
      
      @media ${media_md} {
        font-size: ${public_font_size_xxl};
        line-height: ${public_line_height_xxl};
      }
    ` : ''}

    ${size === 'XL' ? `
      font-size: ${public_mobile_font_size_xl};
      line-height: 38px;
      @media ${media_md} {
        font-size: ${public_font_size_xl};
        line-height: ${public_line_height_xl};
      }
    ` : ''}

    ${size === 'LG' ? `
      font-size: ${public_mobile_font_size_lg};
      @media ${media_md} {
        font-size: ${public_font_size_lg};
        line-height: ${public_line_height_lg};
      }
      ${props.scaled ? `
        font-size: 20px;
        line-height: 30px;
        @media ${media_md} {
          font-size: 24px;
          line-height: 34px;
        }
      ` : ''}
    ` : ''}

    ${size === 'MD' ? `
      font-size: ${public_mobile_font_size_md};
      line-height: 25px;
      @media ${media_md} {
        font-size: ${public_font_size_md};
        line-height: ${public_line_height_md};
      }

      ${props.large ? `
        font-size: 18px;
        line-height: 28px;
        @media ${media_md} {
          font-size: 20px;
          line-height: 30px;
        }
      ` : ''}
    ` : ''}

    ${size === 'SM' ? `
      font-size: ${public_mobile_font_size_sm};
      line-height: 24px;
      @media ${media_md} {
        font-size: ${public_font_size_sm};
        line-height: ${public_line_height_sm};
      }
    ` : ''}


    ${props.bold ? `
      font-weight: 700;
    ` : ''}

    ${props.semibold ? `
      font-weight: 500;
    ` : ''}

  `;
}

export default TypographyTheme;
