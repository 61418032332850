import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import {get} from 'lodash';

const LinkWithParams = (props) => {
  const [rendered, setrendered] = useState()
  let history = useHistory();
  useEffect(() => {
    setrendered(true)
  }, [])
  return <Link {...props} to={`${props.to}${rendered ? `${window.location.search ? `/${window.location.search}` : ''}` : get(history, 'location.search') ? `/${get(history, 'location.search')}` : ''}`}/>
}

export default LinkWithParams;