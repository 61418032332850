import React, { useEffect } from 'react';
import {get} from 'lodash';
import { useQuery } from '@apollo/client';
import {HelmetWrapper, basicHelmetGenerator} from 'utility/DefaultMeta';
import Hero from './components/Hero/Hero';
import LandingSearch from './components/LandingSearch/LandingSearch';
import LandingSolutions from './components/LandingSolutions/LandingSolutions.js';
import LandingFeaturedListings from './components/LandingFeaturedListings/LandingFeaturedListings.js';
import LandingInquire from "./components/LandingInquire/LandingInquire";
import LandinContentPosts from "./components/LandinContentPosts/LandinContentPosts";

import PageViewWrapper from 'higherOrder/PageViewWrapper';
import {
  LandingSection,
  LandingPage,
  Mri
} from './Landing.styles';
import GetStarted from 'components/PublicPages/GetStarted';

import { GET_LISTINGS } from 'state/listings/gql';
import { GET_CONTENTS } from 'state/content/gql';


import { variables } from 'theme/variables';

const {
  custom_light_grey,
  custom_blue
} = variables;



const Landing = (props) => {

  const { loading, error, data } = useQuery(GET_LISTINGS);
  const contentQuery = useQuery(GET_CONTENTS);

  const listings = get(data, 'Listings')
  const content = get(contentQuery, 'data.Contents');
  return (
    <LandingPage>
        <HelmetWrapper metadata={basicHelmetGenerator({
          title:'Magnetic Resonance Technologies - Ensure Artifact Free Imaging',
          description:'MRT is the leading supplier of new and refurbished MRI equipment and parts. MRI sales, service, storage, construction, and mobile MRI.',
        })} />

        <LandingSection style={{minHeight:'520px', paddingBottom: 0}}>
          <Hero />
          <Mri />
        </LandingSection>
        
        <LandingSection style={{zIndex: 2, position: 'relative',  maxWidth: '1282px', background: 'transparent', margin: '-80px auto 0'}}>
          <LandingSearch hideWhenGlobalSearchOpen={true}/>
        </LandingSection>
        
        <LandingSection>
          <LandingSolutions />
        </LandingSection>
        
        <LandingSection>
          <LandingFeaturedListings listings={listings || []} />
        </LandingSection>
  

        <div style={{background: custom_blue}}>
          <LandingInquire  {...props}/>
        </div>

        {/*<LandingSection>
          <LandinContentPosts content={content ||[]}  {...props}/>
        </LandingSection>*/}
        
        <LandingSection background={custom_light_grey}>
          <GetStarted />
        </LandingSection>

    </LandingPage>
  )
}



export default PageViewWrapper(Landing, {pageName: 'Landing'})
