import React from 'react';
import styled from 'styled-components/macro';
import TypographyTheme from './Typography.js';
import ButtonTheme from 'theme/ThemeProviderOverrides/Button';
import TextFieldTheme from 'theme/ThemeProviderOverrides/TextField.js';
import GridTheme from 'theme/ThemeProviderOverrides/Grid';
import TableTheme from 'theme/ThemeProviderOverrides/Table';
import TagTheme from 'theme/ThemeProviderOverrides/Tag';
import TabTheme from 'theme/ThemeProviderOverrides/Tab';
import DropdownTheme from 'theme/ThemeProviderOverrides/Dropdown';
import { ThemeProvider } from '@zendeskgarden/react-theming'
import { Page } from 'theme/Page';
import { variables } from 'theme/variables';


const PublicTheme = {
  ...TagTheme,
  ...TabTheme,
  ...TableTheme,
  ...TypographyTheme,
  ...ButtonTheme,
  ...GridTheme,
  ...DropdownTheme,
  ...TextFieldTheme
}

const {
  custom_dark_blue,
  public_font_size_sm,
  color_white
} = variables;


export const PublicPageWrapper = styled(Page)`
  padding: 0 !important;
  overflow: hidden;
  bvackground: ${color_white}
  li {
    font-size: ${public_font_size_sm};
    line-height: 30px;
    span {
      font-size: 30px;
      position: relative;
      top: 5.9px;
      color: #D7D7D7;
      padding-right: 10px;
    }
  }

  a {
    
    &:link, &:focus, &:hover, &:active, &:visited {
      text-decoration: none;
    }
  }


  .parallax-outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .fade-in {
    opacity: 0;
    &.animated {
      opacity: 1;
    }
  }
  .fade-in-up {
    transform: translateY(50px);
    opacity: 0;
    &.animated {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
  .fade-in-left {
    transform: translateX(-50px);
    opacity: 0;
    &.animated {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
  .fade-in-right {
    transform: translateX(50px);
    opacity: 0;
    &.animated {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
  
`;



const PublicPage = (props) => {
  return (
    <ThemeProvider theme={PublicTheme}>
      <PublicPageWrapper style={props.style || {}}>
        {props.children}
      </PublicPageWrapper>
    </ThemeProvider>
  )
}

export default PublicPage;



