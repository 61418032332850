import React from 'react';
import {Wrapper, ItemContainer, ItemContainerWrapper, LoadingLine} from './ProductItemSlider.styles'


const ProductSliderItem = (props) => {
  return (
    <Wrapper style={props.style || {}} className={"wrap"}>
      <ItemContainerWrapper>
        <ItemContainer style={{minHeight: '300px', padding: '30px'}}>
          <LoadingLine style={{width: '90px', height: "90px", margin: '10px auto 10px'}} />
          <LoadingLine />
          <LoadingLine />
          <LoadingLine />
        </ItemContainer>
      </ItemContainerWrapper>
    </Wrapper>
  )
}


export default ProductSliderItem