import React from 'react';
import { StyledLandingInquire } from './LandingInquire.styles.js';



const LandingInquire = (props) => {
  return (
    <StyledLandingInquire>

    </StyledLandingInquire>

  )
}


export default LandingInquire;
