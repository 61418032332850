import styled from 'styled-components/macro';
import { Col } from '@zendeskgarden/react-grid';
import { LG  } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';


const {
  custom_pale_blue,
  media_md,
} = variables;



export const StyledLandingSolutions = styled.div`
  position: relative;
`

export const Partner = styled.div`
  padding: 10px 4px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  height: 100%;
  cursor: pointer;
  img {
    cursor: pointer;
    width: 90%;
    height: auto;
    transition: all 0.3s ease;
  }
  &:hover {
    box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    img {
      transform: scale(1.01);
    }
  }
  @media ${media_md} {
    padding: 20px;
  }
`

export const Columns = styled.div`
  position: relative;
  column-count: 1;
  column-gap: 40px;
  @media ${media_md} {
    column-count: 2;
  }
`

export const Divider = styled('div')`
  margin-top: 80px; 
  margin-bottom:30px;
  position: relative;
`

export const IntroText = styled(LG)`
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  width: 300px;
  top: -19px;
  z-index: 3;
  font-size: 34px !important;
  font-weight: 300 !important;
  text-align: center;
`



export const IntroTextTwo = styled(LG)`
  text-align: center;
  z-index: 4;
  position: relative;
  padding-top: 25px;
  font-size: 24px !important;
`


export const Image = styled.img`
  box-shadow: 0px -15px 0px 12px #ffffff; 
  background: #fff;   
  position: relative;
  margin: 0 auto;
  display: block;
  max-width: 250px;
  @media(max-width: 600px) {
    max-width: 170px;
  }
`

export const Line = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.16);
  height: 1px;
  &:before {
    content: "";
    left: 0;
    right: 0;
    margin: 0 auto;
    display: block;
    max-width: 279px;
    background: #fff;
    height: 2px;
    @media(max-width: 600px) {
      max-width: 200px;
    }
  }
`