import styled from 'styled-components/macro';
import { Col } from '@zendeskgarden/react-grid';
import { SM  } from '@zendeskgarden/react-typography';
import { variables } from 'theme/variables';


const {
  custom_pale_blue,
  media_md,
  media_sm
} = variables;



export const StyledLandinContentPosts = styled.div`
  position: relative;
`
