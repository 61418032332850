import { gql } from '@apollo/client';


export const GET_CONTENTS = gql`
  query getLandingContent {
    Contents(content_resource_id: 1)  {
      id
      content_slug
      content_title
      content_resource_id
      content_author_id
      content_description
      content_data
      deleted_at
      image
      status
      updated_at
    }
  }
`;


export const GET_CONTENTS_DETAILS = (id) => {
  return gql`
    query getContentDetails {
      Content(id: ${id})  {
        id
        content_slug
        content_title
        content_resource_id
        content_author_id
        content_description
        content_data
        deleted_at
        image
        status
        updated_at
      }
    }
  `;
}


export const UPDATE_CONTENTS_DETAILS = gql`
  mutation UpdateContent($id: Int, $input: UpdateContentInput!) {
    UpdateContent(id:$id, input:$input) {
      id
      content_slug
      content_title
      content_resource_id
      content_author_id
      content_description
      content_data
      deleted_at
      image
      status
      updated_at
    }
  }
`;


export const ADD_CONTENTS_DETAILS = gql`
  mutation AddContent($input: AddContentInput!) {
    AddContent(input:$input) {
      id
      content_slug
      content_title
      content_resource_id
      content_author_id
      content_description
      content_data
      deleted_at
      image
      status
      updated_at
    }
  }
`;