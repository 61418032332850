import styled from 'styled-components/macro';


export const Wrapper = styled.div`
    &.pipe-facts-slider {
      .slider-item-container {
        overflow: visible;
      }
    }

    div {
      .scroll-wrapper {
        margin-top: 12px;
        white-space: nowrap;
        overflow-x: scroll;
        display: none;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-wrap: nowrap;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      
      @media (max-width: 950px) {
        .desktop-view {
          display: none;
        }
        &.p-slick-init .mobile-view {
          padding-left: 7px;
          display: flex !important;
          padding-bottom: 0;
          white-space: nowrap;
          overflow-x: scroll;
          display: none;
          justify-content: flex-start;
          flex-wrap: wrap;
          flex-wrap: nowrap;
          -webkit-overflow-scrolling: touch;
          &::-webkit-scrollbar {
            display: none;
          }
          & > a, & > .wrap {
            white-space: normal;
            width: 100%;
            min-width: 32%;
            align-items: stretch;
            height: auto;
            flex-grow: 0;
          }
        }
      }
      @media (max-width: 750px) {
        &.p-slick-init .mobile-view > a, &.p-slick-init .mobile-view > .wrap {
          min-width: 40.3%;
        }
      }
      @media (max-width: 600px) {
        &.p-slick-init .mobile-view > a, &.p-slick-init .mobile-view > .wrap {
          min-width: 60.3%;
        }
      }
      @media (max-width: 500px) {
        &.p-slick-init .mobile-view > a, &.p-slick-init .mobile-view > .wrap {
          min-width: 70.3%;
        }
      }

      @media (max-width: 340px) {
        &.p-slick-init .mobile-view > a, &.p-slick-init .mobile-view > .wrap {
          min-width: 90.3%;
        }
      }



      // SERVER SLICK
      &.server-slick {
        .desktop-view {
          display: block !important;
          .p-slick-track {
            display: flex;
            max-width: 100%;
            .p-slick-slide {
              min-width: 16.6666%;
              max-width: 16.6666%;
              width: 100% !important;
              .offer-product-title {
                max-height: 57px;
                height: 57px;
                overflow: hidden;
              }
              @media (max-width: 1200px) {
                min-width: 20%;
                max-width: 20%;
              }
              @media (max-width: 950px) {
                min-width: 23.3%;
                max-width: 23.3%;
              }
              @media (max-width: 750px) {
                min-width: 30.3%;
                max-width: 30.3%;
              }
              @media (max-width: 600px) {
                min-width: 32.3%;
                max-width: 32.3%;
              }
              @media (max-width: 500px) {
                min-width: 44.3%;
                max-width: 44.3%;
              }
              @media (max-width: 400px) {
                min-width: 48.3%;
                max-width: 48.3%;
              }
              @media (max-width: 340px) {
                min-width: 90.3%;
                max-width: 90.3%;
              }
            }
          }
        }
      }
    }
 
`