import styled from 'styled-components/macro';
import { variables } from 'theme/variables';

const {
  custom_navy,
  custom_slate,
  media_sm,
  media_md,
  custom_blue,
} = variables;


export const StyledLandingSearchWrapper = styled.div`
  background-color: #fff;
  padding: 0;
  border-radius: 4px;
  position: relative;
  width: 100%;
  margin: 0 auto;
  .item-selection-filters {
    padding: 0;
  }
  @media ${media_md} {
    box-shadow: 0 20px 26px rgba(10,32,83,0.1);
    margin-bottom: 50px;
    padding: 33px 0 0 0;
    .item-selection-filters {
      padding: 20px 20px 0;
    }
  }
  @media (max-width: 600px) {
    padding-top: 50px;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin: 0 -10px;
  button {
    font-size: 12px;
    padding: 0;
    height: 31px;
    line-height: 28px;
    min-width: 77px;
    opacity: 0;
    transition: all 0.3s ease;
    &.show {
      opacity: 1;
    }

    @media (min-width: 900px) {
      position: absolute;
      bottom: -21px;
      left: 0;
      right: 0;
      margin: 0 auto;
      font-size: 16px;
      height: 38px;
      line-height: 37px;
      border-radius: 18px;
      font-weight: bold;
    }
  }
  @media ${media_md} {
    padding: 0 40px;
    margin: 0 auto;
  }
`


export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0 0px 0;
  margin: 0px -10px;
  flex-wrap: wrap;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 40px;
  margin-top: 20px;
  flex: 1;
  height: 100%;
  max-width: 130px;
  transition: background 0.3s ease;
  cursor: pointer;
  min-width: 25%;
  div {
    cursor: pointer;
    transition: background 0.3s ease;
  }
  img {
    cursor: pointer;
    height: 84px;
    max-width: 100%;
    margin: 0 auto;
  }
  &:hover {
    background: rgb(240, 241, 249, 0.6);
    div {
      color: ${custom_blue};
    }
  }
  @media (max-width: 600px) {
    max-width: 50%;
    width: 50%;
    padding: 0;
    min-width: 50%;
    margin-bottom: 25px;
  }
  
`

export const StyledLandingSearch = styled.div`
  background-color: #fff;
  margin: 0 auto;
  padding: 0 10px;
  &&& .filter-wrapper.not_small {
    max-width: 400px;
    @media (max-width: 1050px) {
      min-width: 130px !important;
    }
    @media (max-width: 780px) {
      min-width: 33% !important;
    }
    @media (max-width: 580px) {
      min-width: 50% !important;
    }
  }

  &&& .filter-title {
    font-size: 17px;
    color: ${custom_navy};
    @media (max-width: 780px) {
      font-size: 15px;
    }
  }

  &&& .filter-item-input.operator {
    font-size: 13px;    
  }

  &&& .filter-item-input {
    font-size: 14px;
    color: ${custom_navy};
    *, & ~ div {
      font-size: 14px;
    }
    &:before {
      opacity: 0.5;
    }
    &.operator {
      color: ${custom_slate};
    }
  }

  .filter-item-group-header {
    margin-bottom: 25px !important;
  }

`
