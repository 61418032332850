import React, {useState} from 'react';
import { Button } from 'theme/Button';
import { Link } from 'react-router-dom';
import {SM, LG} from "@zendeskgarden/react-typography";


import {ProductImageWrapper, Wrapper, ItemContainer, ProductImage, ProductContent, ItemContainerWrapper} from './ProductItemSlider.styles'


const ProductSliderItem = (props) => {
  const [image] = useState(props.image.replace('f_auto', 'f_auto,h_280,w_280'))
  return (
    <Wrapper style={props.style || {}} className={"wrap"}>
      <ItemContainerWrapper className="item-container-wrapper">
        <ItemContainer style={{paddingBottom: '0px'}}>
          <ProductImageWrapper>
            <ProductImage noImage={!props.image_url} src={image} alt={props.listing_name} loading="lazy"/>
          </ProductImageWrapper>
          <ProductContent>
            <SM semibold uppercase slate>{props.listing_brand} {props.listing_model} {props.listing_model && props.listing_model.indexOf('Mobile') === -1 ? props.listing_type : ''}</SM>
            <LG navy paddingTopSm semibold style={{lineHeight: '24px'}}>{props.listing_name}</LG>
          </ProductContent>
          <Link to={{
            pathname: props.url || `/l/${props.listing_slug||'listing'}/${props.id}`,
            state: {
              fromInternal: props.fromInternal
            }
          }}
          style={{position: 'absolute', top: 0, right: 0, bottom: -55, left: 0}}></Link>
        </ItemContainer>
        <Button
          bold
          style={{
            borderRadius: '8px',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            margin: '0',
            display: 'block',
            border: 'none',
            marginTop: '20px',
            color: '#3465e6',
            fontSize: '12px',
            pointerEvents: 'none'
          }}>
          View Item
        </Button>
      </ItemContainerWrapper>
    </Wrapper>
  )
}




export const ProductSliderItemEditable = (props) => {
  return (
    <Wrapper onClick={props.onClick} style={props.style || {}} className={"wrap"}>
      <ItemContainerWrapper className="item-container-wrapper">
        <ItemContainer style={{paddingBottom: '0px'}}>
          <ProductImageWrapper>
            <ProductImage noImage={!props.image_url} src={props.image} alt=""/>
          </ProductImageWrapper>
          <ProductContent>
            <SM semibold uppercase slate>{props.listing_brand} {props.listing_model} MRI</SM>
            <LG navy paddingTopSm semibold style={{lineHeight: '24px'}}>{props.listing_name}</LG>
          </ProductContent>
          
        </ItemContainer>
        <Button
          bold
          style={{
            borderRadius: '8px',
            borderTopRightRadius: 0,
            borderTopLeftRadius: 0,
            margin: '0',
            display: 'block',
            border: 'none',
            marginTop: '20px',
            color: '#3465e6',
            fontSize: '12px',
            pointerEvents: 'none'
          }}>
          Edit Item
        </Button>
      </ItemContainerWrapper>
    </Wrapper>
  )
}


export default ProductSliderItem