import React from 'react';
import Slider from 'components/SlickSlider';
import {debounce, get, forEach, filter, map} from 'lodash';
import ProductSliderItem from './components/ProductSliderItem';
import ContentSliderItem from './components/ContentSliderItem';
import SliderItemLoader from './components/SliderItemLoader';
import {Wrapper} from './RelatedProductsSlider.styles.js';


class RelatedProductsSlider extends React.Component {

  constructor(...args) {
    super(...args);
    this.resizeHandler = debounce(this.handleResize.bind(this), 20);
    this.state = {
      slidesToShow: this.props.slidesToShow || 5
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
    this.handleResize();
    this.setState({
      render: true
    });
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  handleResize(e) {
    const s = this.state.slidesToShow;
    const w = window.innerWidth;
    const maxSlides = this.props.maxSlides || 6;
    if (w >= 20000 && s !== 6) {
      this.setState({
        slidesToShow: maxSlides || 6,
        hideSlides: false
      });
    } else if (w > 1240 && w <= 20000 && s !== 5) {
      this.setState({
        slidesToShow: maxSlides || 5,
        hideSlides: false
      });
    } else if (w > 950 && w <= 1240 && s !== 4) {
      this.setState({
        slidesToShow: maxSlides > 4 ? 4 : maxSlides,
        hideSlides: false
      });
    } else if (w <= 950 && !this.state.hideSlides) {
      this.setState({
        hideSlides: true,
        slidesToShow: null,
      });
    }
  }

  render() {
    let originalData = get(this.props, 'data.length') ? get(this.props, 'data') : [1,2,3,4,5,6];
    const loading = !get(this.props, 'data.length');
    const innerWidth = this.state.render ? window.innerWidth > 767 : false;
    const settings = {
      className: 'center',
      infinite: false,
      slidesToShow: this.state.slidesToShow,
      initialSlide: this.state.active,
      slidesToScroll: 3,
      draggable: false,
      touchThreshold: 5000,
      speed: 300,
      arrows: true,
      pauseOnHover: innerWidth,
      afterChange: (i) => {}
    };

    const ghosts = []
    forEach(originalData, (el, i) => {
       const unitWidth = get(el, 'unitWidth', 1);
       if (unitWidth && unitWidth > 1) {
        ghosts.push({ghost: true})
      }
    });

    const data = originalData.concat(ghosts)
    let itemCount = data.length;
  
    return (
      <Wrapper style={{minWidth: '100%'}}>
        <div className={`p-slick-init ${loading ? 'loading' : ''} ${!this.state.render ? 'server-slick' : ''} length-${data.length} ${itemCount <= this.state.slidesToShow ? 'depleted' : ''}`}>
          {
            !this.state.hideSlides && loading ?
            <div className="desktop-view">
              {this.renderSlider(data, settings, true)}
            </div> :
            !this.state.hideSlides ?
            <div className="desktop-view">
              <div>
                {this.renderSlider(data, settings, false)}
              </div>
            </div> :
            <div className="mobile-view scroll-wrapper">
              {
                map(filter(data, (d) => !get(d, 'ghost')), (data, i) => {
                  return (
                    
                    loading ?
                    <div key={`${i}-loader`}/> :
                    this.props.content ?
                    <ContentSliderItem style={{paddingRight: '20px'}} fromInternal={this.props.fromInternal} key={i} {...data}  /> :
                    <ProductSliderItem style={{paddingRight: '20px'}} fromInternal={this.props.fromInternal} key={i} {...data} />
                  );
                })
              }
            </div>
          }
        </div>
      </Wrapper>
    );
  }

  renderSlider(data, settings, loading) {
    return (
      <Slider {...settings}>
        {
          map(data, (data, i) => {
            return (
              <div key={`slide-${i}-${loading}`} className="slide">
                {
                  loading ?
                  <SliderItemLoader style={{paddingRight: '20px'}}/> :
                  get(data, 'ghost') ? <div></div> :
                  this.props.content ?
                  <ContentSliderItem style={{paddingRight: '20px'}} fromInternal={this.props.fromInternal} key={i} {...data} /> :
                  <ProductSliderItem style={{paddingRight: '20px'}} fromInternal={this.props.fromInternal} key={i} {...data} />
                }
              </div>
            );
          })
        }
      </Slider>
    );
  }


}

export default RelatedProductsSlider;