import React from 'react';
import LinkWithParams from 'components/LinkWithParams/LinkWithParams';
import {trackClick} from 'utility/analytics';
import history from 'historyContainer';
import {openModal} from 'state/modals/actions';
import { HeroHeader } from './Hero.styles.js';
import { XXL, LG, MD } from '@zendeskgarden/react-typography';
import Flex from 'styled-flex-component';
import { Row, Col } from '@zendeskgarden/react-grid';
import {Button} from 'theme/Button';


const Hero = (props) => {
  function goTorequest() {
    trackClick('inquireForm', 'Submit A Request', 'Landing');
    openModal({
      type: 'inquireFormModal',
    })  
  }
  function getInTouch() {
    trackClick('getInTouch', 'Get In Touch', 'Landing');
    openModal({
      type: 'contactUsModal',
    })    
  }
  return (
      <HeroHeader>
        <div style={{position: 'relative', zIndex: 2}}>
          <Row>
            <Col md={12}>
              <div>
                <Flex alignCenter>
                  <MD bold letterSpacing="true" style={{color: "#a2acc1", fontWeight: 500}} uppercase tag="p">30 Years Of Trusted Sales & Service</MD>
                </Flex>
                
                  <XXL className="mobile" style={{maxWidth: '950px'}}  bold paddingTopLg navy tag="h1">The Industry’s Top Provider of MRI and CT Turn-key solutions. </XXL>
                  <XXL className="desktop" style={{maxWidth: '950px'}}  bold paddingTopLg navy tag="h1">The Industry’s Top Provider of MRI and CT Turn-key solutions with more experience and better references. </XXL>
                
                <LG style={{maxWidth: '800px'}} paddingTopMd navy light tag="h3">
                  With expertise in both <b style={{fontWeight: '500'}}>Machine Procurement</b> and <b style={{fontWeight: '500'}}>Site Construction</b>, MRT is leading the industry in MRI, CT and PET acquisition and sales, Installation, RF Shielding, Maintenance, service, and consultation.
                </LG>
                <LG style={{maxWidth: '800px'}} paddingTopMd navy light tag="h3">Trusted By <a href="https://my.clevelandclinic.org/"><b>The Cleveland Clinic</b></a>, <a href="https://www.uhhospitals.org/"><b>University Hospitals</b></a>, <a href="https://www.upmc.com/"><b>UPMC</b></a>, and <b>100s more</b>.</LG>
              </div>

            </Col>
          </Row>
          <Row style={{marginTop: '30px'}}>
            <Col size={12} sm="auto">
              <Button onClick={goTorequest} pairButton size="large" spacingBottom primary>Submit A Request</Button>
            </Col>  
            <Col size={12} sm="auto">
              <Button onClick={getInTouch} last pairButton size="large" primaryOutline>Get In Touch</Button>
            </Col>
          </Row>
        </div>
      </HeroHeader>

  )
}


export default Hero;
