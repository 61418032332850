import styled from 'styled-components/macro';
import { Section } from '../GlobalPublicStyles';
import { variables } from 'theme/variables';

const {
  custom_light_grey,
  custom_blue
} = variables;


export const GetStartedSection = styled('div')`
  background-color: ${custom_light_grey};
  padding: 40px 0;
  max-width: 960px;
  margin: 0 auto;
  .transparent {
    background-color: transparent !important;
    &:hover {
      background-color: ${custom_blue} !important;
    }
  }
`;
