import styled from 'styled-components/macro';
import {variables} from 'theme/variables';
const {
  custom_border_grey,
} = variables;

export const Wrapper = styled.div`
  flex-wrap: wrap;
  display: flex;
  margin-bottom: 10px;
  margin-top: 10px;
  white-space: normal;
  position: relative;
  width: 100%;
`

export const ProductContent = styled.div`
  flex-wrap: wrap;
  display: block;
  padding-top: 15px;
  white-space: normal;
  position: relative;
`

export const ItemContainerWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  transition: background 0.3s 0s ease, border-color 0.3s ease;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;

  border-radius: 8px;  
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 9px 0px rgba(10, 32, 83, 0.21);
`


export const ItemContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px 15px 15px;
  width: 100%;
`


export const ProductImage = styled.img`
  margin: 0 auto;
  display: block;
  width: 140px;
  height: 147px;
  ${(props) => props.noImage ? `
    padding: 7px 0 0 0;
  ` : ''}
`



export const ProductImageWrapper = styled.div`
  margin: 0 auto;
  display: block;
  height: 140px;
  min-width: 100%;
  ${(props) => props.noImage ? `
    padding: 7px 0 0 0;
  ` : ''}
`

export const LoadingLine = styled.div`
  width: 100%;
  background: #ebebeb;
  height: 12px;
  margin-top: 15px;
`
