import styled from 'styled-components/macro';
import { variables } from 'theme/variables';
const {
  media_md
} = variables;


export const HeroHeader = styled.div`
  background-color: #fff;
  position: relative;
  padding: 0px 0px 10px;
  min-height: 200px;
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
    font-size: 32px;
    line-height: 41px;
  }
  @media ${media_md} {
    padding: 0px 0px 60px;
    min-height: 370px;
     .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
`