import React from 'react';
import {canUseDOM} from 'exenv';
import LinkWithParams from 'components/LinkWithParams/LinkWithParams';
import { Row, Col } from '@zendeskgarden/react-grid';
import {MD, XXL, LG } from '@zendeskgarden/react-typography';
import { StyledLandingSolutions, Line, IntroText,IntroTextTwo, Columns, Divider, Partner } from './LandingSolutions.styles.js';



const LandingSolutions = (props) => {
  return (
    <StyledLandingSolutions>
      
      <Row>
        <Col md={12}>
          <div>
            <MD bold uppercase blue>WORK WITH US IN CONFIDENCE</MD>
            <XXL bold paddingBottomMd paddingTopLg navy tag="h1"> A Fully Integrated Suite of Solutions </XXL>
            <Columns>
            <LG style={{maxWidth: '590px'}} paddingTopMd navy light tag="h3">
                Our top priority is identifying the right equipment for your needs and minimizing your costs.
                We bring together everything that’s required to <LinkWithParams to="/services/install-deinstall-rigging">de-install</LinkWithParams>, <LinkWithParams to="/services/install-deinstall-rigging">deliver</LinkWithParams>, <LinkWithParams to="/services/install-deinstall-rigging">install</LinkWithParams>, and <LinkWithParams to="/services/ongoing-services">maintain</LinkWithParams> your MRI or CT.
            </LG>
            <LG style={{maxWidth: '590px'}} paddingTopMd navy light tag="h3">
              Our 30 years working with companies on <LinkWithParams to="/services/site-preperation">RF Sheilding</LinkWithParams>, <LinkWithParams to="/services">Magnetic Shielding</LinkWithParams>, <LinkWithParams to="/services/ongoing-services">Maintenance & Upgrades</LinkWithParams>, and <LinkWithParams to="/services/ongoing-services">Equipment Services</LinkWithParams> has made us the most trusted company in the business.
              </LG>
            </Columns>
          </div>
          <LG style={{marginTop: '20px'}}><LinkWithParams to="/services">View All Services</LinkWithParams></LG>
        </Col>
      </Row>


      <Row>
        <Col md={12}>
         <Divider>
            <Line></Line>
            <IntroText uppercase light>World Class</IntroText>
            <IntroTextTwo uppercase bold navy>Customers</IntroTextTwo>
          </Divider>

          <Row>
            <Col alignCenter justifyCenter size={6} md={3}>
              <Partner>
                <a target="_blank" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}} href="https://my.clevelandclinic.org/">
                  <img alt="Cleveland Clinic Health" loading="lazy" src="https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1604633114/partners/cc_himsia.png"/>
                </a>
              </Partner>
            </Col>

            <Col alignCenter justifyCenter size={6} md={3}>
              <Partner>
                <a target="_blank" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}} href="https://www.upmc.com/">
                  <img style={{width: '65%'}} alt="UPMC Health" loading="lazy" src="https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1604633114/partners/upmc_hzamot.png"/>
                </a>
              </Partner>
            </Col>

            <Col alignCenter justifyCenter size={6} md={3}>
              <Partner>
                <a target="_blank" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}} href="https://www.summahealth.org/">
                  <img style={{width: '65%'}} alt="Suma Health" loading="lazy" src="https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1604633114/partners/suma_xoqnqj.png"/>
                </a>
              </Partner>
            </Col>

            <Col alignCenter justifyCenter size={6} md={3}>
              <Partner>
                <a target="_blank" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}} href="https://www.uhhospitals.org/">
                  <img alt="UH Hospitals Health" loading="lazy" src="https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1604633114/partners/uh_jjuoc2.png"/>
                </a>
              </Partner>
            </Col>

            <Col alignCenter justifyCenter size={6} md={3}>
              <Partner>
                <a target="_blank" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}} href="https://www.nih.gov/">
                  <img style={{width: '65%'}} alt="NHI Health" loading="lazy" src="https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1604633113/partners/nhi-logo_vlzb0n.png"/>
                </a>
              </Partner>
            </Col>

            <Col alignCenter justifyCenter size={6} md={3}>
              <Partner>
                <a target="_blank" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}} href="https://missionhealth.org/">
                  <img alt="Mission Health" loading="lazy" src="https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1604633113/partners/mission_v9q5hs.png"/>
                </a>
              </Partner>
            </Col>

            <Col alignCenter justifyCenter size={6} md={3}>
              <Partner>
                <a target="_blank" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}} href="https://lakehealth.org/">
                  <img style={{width: '60%'}} alt="Lake Health" loading="lazy" src="https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1604633114/partners/lake_rawpff.png"/>
                </a>
              </Partner>
            </Col>

            <Col alignCenter justifyCenter size={6} md={3}>
              <Partner>
                <a target="_blank" style={{display: 'flex', justifyContent: "center", alignItems: 'center'}} href="https://medquestreviews.com/">
                  <img style={{width: '75%'}} alt="Med Quest" loading="lazy" src="https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto/v1604633113/partners/mqlogo_ip1vah.png"/>
                </a>
              </Partner>
            </Col>

          </Row>
        </Col>
      </Row>

      
    </StyledLandingSolutions>

  )
}


export default LandingSolutions;
