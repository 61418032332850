import React from 'react';
import styled from 'styled-components/macro';
import { Row, Col } from '@zendeskgarden/react-grid';
import { XL, XXL } from '@zendeskgarden/react-typography';
import { Button } from 'theme/Button';
import {trackClick} from 'utility/analytics';
import history from 'historyContainer';
import {openModal} from 'state/modals/actions';
import { variables } from 'theme/variables';

import {
  GetStartedSection
} from './components/GetStarted.styles';

const {
  media_md,
} = variables;



export const EXL = styled(XXL)`
  font-size: 33px !important;
  @media ${media_md} {
    font-size: 40px !important;
  }
`


const GetStarted = (props) => {
   function goTorequest() {
    trackClick('inquireForm', 'Submit A Request', 'LandingHero');
    openModal({
      type: 'inquireFormModal',
    })  
  }
  function getInTouch() {
    trackClick('getInTouch', 'Get In Touch', 'LandingHero');
    openModal({
      type: 'contactUsModal',
    })    
  }

  return (
    <GetStartedSection>
      <Row justifyContent="between">
        
        <Col size="12" sm="auto">
          <EXL bold navy tag="h2">
            Work With Us
          </EXL>
          <XL light paddingTopMd slate tag="p">
            Request Equipment Procurement or Service
          </XL>
        </Col>

        <Col size="12" sm="auto">
          <Row alignCenter style={{marginTop: 20}}>
            <Col alignCenter size={12} sm="auto">
              <Button onClick={goTorequest} pairButton size="large" primary>
                Inquire
              </Button>
            </Col>
            <Col size={12} sm="auto">
              <Button className="transparent" last pairButton size="large" primaryOutline onClick={getInTouch}>
                Contact Us
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </GetStartedSection>
  )
}

export default GetStarted;
