import { gql } from '@apollo/client';


export const GET_LISTINGS = gql`
  query getLandingListings {
    Listings(listing_resource_id: 1, where: {listing_resource_id: 1, active: true})  {
      id,
      listing_slug,
      listing_brand,
      listing_model,
      listing_name,
      listing_resource_id
      listing_description
      listing_style
      listing_type
      image
      active
      approved
    }
  }
`;


export const GET_LISTING_DETAILS = (id) => {
  return gql`
    query getListingDetails {
      Listing(id: ${id})  {
        id
        listing_name
        listing_resource_id
        listing_description
        listing_price
        listing_data
        listing_brand
        listing_model
        listing_part_number
        listing_slug
        listing_style
        listing_type
        delete_date
        image        
        deleted_at
      }
    }
  `;
}


export const GET_LISTINGS_WITH_DATA = gql`
  query getDashboardListings {
    Listings(listing_resource_id: 1)  {
      id,
      listing_slug,
      listing_brand,
      listing_model,
      listing_name,
      listing_resource_id
      listing_description
      listing_style
      listing_type
      listing_data
      image
      active
      approved
    }
  }
`;