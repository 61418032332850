import { Grid, Row, Col } from '@zendeskgarden/react-grid';
import React from 'react';
import styled from 'styled-components/macro';
import PublicPage from 'components/PublicPages/PublicPage';
import { variables } from 'theme/variables';
const {
  media_sm,
  public_app_max_width,
  media_lg
} = variables;


export const StyledLandingSection = styled('div')`
  background-color: ${(props) => props.background || '#fff'};
  h3 {
    line-height: 33px !important;
  }
`;



export const LandingPage = styled(PublicPage)`
  padding-top: 0px;
`;


export const LandingSectionInner = styled('div')`
  max-width: ${public_app_max_width};
  margin: 0 auto;
  padding: ${(props) => '20px' || '0'} 15px 50px;
  @media ${media_sm} {
    padding: ${(props) => '50px' || '0'} 40px 50px;
  }
  @media (min-width: 1430px) {
    padding: ${(props) => '50px' || '0'} 20px 50px;
  }
`;


export const Mri = styled.div`
  background-image: url("https://res.cloudinary.com/hbq7zoknx/image/upload/f_auto,w_auto,c_scale,dpr_auto/c_limit,w_1904/v1604633394/mri-2_hbopgn.jpg");
  position: absolute;
  top: 0;
  bottom: 0;
  right: -40px;
  background-size: contain;
  background-repeat: none;
  background-repeat: no-repeat;
  width: 70%;
  z-index: 1;
  height: 520px;
  background-position: right;
  opacity: 0.8;
  @media (max-width: 900px) {
    display: none;
  }
`

export const LandingSection = (props) => {
  return (
    <StyledLandingSection background={props.background}>
      <LandingSectionInner background={props.background} style={props.style || {}}>
        <Grid>
          <Row>
            <Col style={props.colStyle || {}}>
              {props.children}
            </Col>
          </Row>
        </Grid>
      </LandingSectionInner>
    </StyledLandingSection>
  )
}
